<template>
  <div>
    <h1>給与連携ファイル出力</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col colspan="12">
                <DatePicker
                  class="mt-10"
                  v-model="date"
                  label="対象年月"
                  type="month"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="12">
                <v-autocomplete
                  v-model="selectFilterAffiliations"
                  :items="filterAffiliations"
                  label="所属"
                  clearable
                  multiple
                  chips
                  outlined
                  item-text="name"
                  item-value="code"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col colspan="6">
                <v-radio-group
                  v-model="filterCalc"
                  row
                  label="出力済みの表示:"
                  prepend-icon="mdi-toggle-switch"
                >
                  <v-radio label="しない" :value="SelectsConfirm.No"></v-radio>
                  <v-radio label="する" :value="SelectsConfirm.Yes"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getselect()">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-actions class="ma-2">
        <span style="color:red"
          >連携用ファイルをダウンロードする場合は、社員の□に☑を入れてください。</span
        >
        <v-spacer />
        <Btn
          icon="mdi-download"
          color="success"
          text
          outlined
          @click="download"
          :disabled="!selected.length"
        >
          連携ファイルダウンロード
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        multi-sort
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="item.disabled"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { BUSYO_CODE as BusyoCode } from "@/defines";
const DATE = new Date();

const SelectsConfirm = {
  No: "0",
  Yes: "1"
};
export default {
  name: "SmileLinkageKyuyo",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      disabledCount: 0,
      displayDate: "",
      SelectsConfirm,
      filterAffiliations: [],
      selectFilterAffiliations: [],
      filterCalc: SelectsConfirm.No,
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    selectAllToggle(props) {
      console.log("selectAllToggle", props);
      if (!props.value) {
        this.selected = [];
        return;
      }
      if (this.selected.length != this.items.length - this.disabledCount) {
        this.selected = [];
        props.items.forEach(item => {
          this.selected.push(item);
        });
      } else this.selected = [];
    },
    async getdata() {
      console.log("getdata");
      try {
        this.filterAffiliations = await this.$get(
          this.Paths.shozokuFilterForCalc
        );
        const data = await this.$get(this.Paths.OutputCalc);
        await this.setdata(data);
        this.displayDate = await this.getPaymentDate();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getselect() {
      const paymentDate = await this.getPaymentDate();
      const param = {
        paymentDate: paymentDate,
        affiliationIds: this.selectFilterAffiliations,
        calcFlag: this.filterCalc
      };

      await this.getSelectWithParam(param);
    },
    async getSelectWithParam(param) {
      console.log("getselect");
      this.$loading();
      try {
        this.selected = [];
        this.disabledCount = 0;
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const data = await this.$get(this.Paths.OutputCalc, "query=" + encode);
        await this.setdata(data);
        this.displayDate = param.paymentDate;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
      this.items.map(item => {
        if (item.disabled) this.disabledCount += 1;
      });

      this.displayDate = "";
    },
    async download() {
      console.log("download", this.selected);
      if (this.selected.length == 0) {
        this.$warning("社員が選択されていません");
        return;
      }

      const paymentDate = await this.getPaymentDate();
      if (paymentDate !== this.displayDate) {
        this.$warning("選択した年月と表示しているデータが異なります。");
        return;
      }

      this.$loading();

      try {
        const date = new Date(this.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const targetmonth = year + "-" + ("00" + month).slice(-2);

        const incodes = [];
        this.selected.forEach(s => {
          incodes.push(s.incode);
        });

        const param = {
          incodes: incodes,
          targetmonth: targetmonth
        };

        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const result = await this.$downloadexcel(
          "/smile-linkage",
          "query=" + encode
        );

        const url = URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "download-filename.zip";
        link.click();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
      await this.getselect();
    },
    async getTargetDateString(targetDate) {
      return (
        targetDate.getFullYear() +
        "-" +
        targetDate.getMonth() +
        "-" +
        targetDate.getDate()
      );
    },
    async getPaymentDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return result;
    }
  },
  async created() {
    console.log("==created");
    this.date = this.$getsecurityehimeServiceDate();
    console.log(this.date);
    await this.getdata();
    console.log("query", this.$route.query);
    if ("filter" in this.$route.query) {
      const param = this.$route.query.filter;
      if (typeof param !== "undefined" && Object.keys(param).length) {
        this.selectFilterAffiliations = param.affiliationIds;
        this.filterCalc = param.calcFlag;
        this.date = param.paymentDate;
        await this.getSelectWithParam(param);
      }
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
